




















import { Watch } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import { Route } from "vue-router";

import PageBanner from "@/components/ui/PageBanner.vue";
import ProjectsArticleNav from "@/components/project/ProjectsArticleNav.vue";

import { TechnologyMixin } from "@/mixins/technology.mixin";

import { ProjectService } from "@/services/project.service";

import { technologies } from "@/mock/technologies";

import { Technology } from "@/interfaces/technology";
import { Project } from "@/interfaces/project";

import { TechnologySlug } from "@/enums/technology-slug.enum";

@Component({
  components: {
    PageBanner,
    ProjectsArticleNav
  }
})
export default class TechnologyPage extends mixins(TechnologyMixin) {
  @Watch("$route", { immediate: true }) private routeChanged(
    newRoute: Route
  ): void {
    this.technology = technologies.find(
      tech => tech.slug === newRoute.params.technologyName
    );
  }
  technology: Technology | undefined | null = null;

  /** Returns projects which were created using current technology */
  get featuredProjects(): Project[] {
    return ProjectService.getProjectsWithTechnologySlug(
      this.technology?.slug as TechnologySlug
    );
  }
}

import { projects } from "@/mock/projects";

import { Project } from "@/interfaces/project";

import { TechnologySlug } from "@/enums/technology-slug.enum";

/** Project based functionalities class */
export abstract class ProjectService {
  static getProjectsWithTechnologySlug(
    technologySlug: TechnologySlug
  ): Project[] {
    return projects.filter(
      project =>
        project.technologies.filter(tech => tech.slug === technologySlug)
          .length > 0
    );
  }
}
